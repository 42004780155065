import React from 'react';
import styled from 'styled-components';

const HotelPage = () => {
  return (
    <>
      <h1 className='heading'>
        Hotel & <br />
        Transportation
      </h1>
      <section>
        <p>
          We will have a shuttle running between the Springhill Suites and the venue before and
          after the wedding, allowing everyone to enjoy themselves without needing to worry about
          transportation.
        </p>
        <ArticleWrapper>
          <h2>Springhill Suites</h2>
          <p>
            125 East Jefferson Street <br />
            Butler, Pennsylvania 16001
          </p>
          <p>724-256-9844</p>
          <p>
            <a
              href='https://www.marriott.com/hotels/travel/pitut-springhill-suites-pittsburgh-butler-centre-city/'
              target='_blank'
              rel='noreferrer'
            >
              Book Room at Springhill Suites
            </a>
          </p>
        </ArticleWrapper>
        <ArticleWrapper>
          <h2>Shuttle Service</h2>
          <h3 className='subheader'>Pre-event</h3>
          <p>
            A shuttle will be running between the hotel and the venue before the ceremony from 3 -
            5pm. We recommend arriving at the hotel first, checking in and getting ready before
            catching the shuttle.
          </p>
          <h3 className='subheader'>Post-event</h3>
          <p>
            A shuttle will be running between the hotel and the venue at the end of the night from
            9:45 - 11:45pm.
          </p>
        </ArticleWrapper>
      </section>
    </>
  );
};

const ArticleWrapper = styled.article`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export default HotelPage;
